var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "ma-0 mt-6",
          attrs: { align: "start", justify: "start", dense: "" }
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", [_vm._v("Dados da Solicitação")]),
            _c("hr")
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("disciplina:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.request != null ? _vm.request.classTO.subject.code : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("turma:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.request != null ? _vm.request.classTO.code : "")
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("semestre:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.request != null ? _vm.request.classTO.semester : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("ano:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.request != null ? _vm.request.classTO.year : "")
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("opção:")]),
              _vm._v(
                " " + _vm._s(_vm.request != null ? _vm.request.priority : "")
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("situação:")]),
              _vm._v(
                " " + _vm._s(_vm.request != null ? _vm.request.deferred : "")
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "8" } }, [
            _c("span", [
              _c("strong", [_vm._v("objetivo:")]),
              _vm._v(
                " " + _vm._s(_vm.request != null ? _vm.request.objective : "")
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }