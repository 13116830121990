<template>
  <div>
    <v-row align="start" justify="start" dense class="ma-0">
      <v-col cols="12">
        <h3>Dados Pessoais</h3>
        <hr />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <span
          ><strong>nome:</strong>
          {{ candidate != null ? candidate.name : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <span
          ><strong>email:</strong>
          {{ candidate != null ? candidate.email : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <span
          ><strong>nasc:</strong>
          {{ candidate != null ? candidate.birthDate : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <span
          ><strong>gênero:</strong>
          {{ candidate != null ? candidate.sex : "" }}</span
        >
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <span
          ><strong>tel:</strong>
          {{ candidate != null ? candidate.primary_cell_phone : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <span
          ><strong>cidade:</strong>
          {{ candidate != null ? candidate.primary_city : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <span
          ><strong>estado:</strong>
          {{ candidate != null ? candidate.primary_state : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <span
          ><strong>país:</strong>
          {{ candidate != null ? candidate.country : "" }}</span
        >
      </v-col>
    </v-row>

    <v-row align="start" justify="start" dense class="ma-0 mt-6">
      <v-col cols="12">
        <h3>Dados Acadêmicos</h3>
        <hr />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <span
          ><strong>formação:</strong>
          {{ candidate != null ? candidate.nivelFormacao : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="8">
        <span
          ><strong>Instituição:</strong>
          {{ candidate != null ? candidate.nomeDaInstituicao : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <span
          ><strong>curso:</strong>
          {{ candidate != null ? candidate.cursoSuperior : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <span
          ><strong>conclusão:</strong>
          {{ candidate != null ? candidate.dataConclusao : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <span
          ><strong>cidade:</strong>
          {{ candidate != null ? candidate.cidadeInstituicao : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <span
          ><strong>país:</strong>
          {{ candidate != null ? candidate.paisInstituicao : "" }}</span
        >
      </v-col>
    </v-row>

    <v-row align="start" justify="start" dense class="ma-0 mt-6">
      <v-col cols="12">
        <h3>PosComp</h3>
        <hr />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <span
          ><strong>número:</strong>
          {{ candidate != null ? candidate.poscompNumber : "" }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <span
          ><strong>Instituição:</strong>
          {{ candidate != null ? candidate.poscompYear : "" }}</span
        >
      </v-col>
    </v-row>

    <v-row align="start" justify="start" dense class="ma-0 mt-6">
      <v-col cols="12">
        <h3>Dados Profissionais</h3>
        <hr />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <span
          ><strong>empresa:</strong>
          {{ candidate != null ? candidate.company : "" }}</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CandidateInformation",
  props: ["candidate"],

  data() {
    return {};
  },
};
</script>

<style>
</style>