var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "ma-0",
          attrs: { align: "start", justify: "start", dense: "" }
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", [_vm._v("Dados Pessoais")]),
            _c("hr")
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("nome:")]),
              _vm._v(
                " " + _vm._s(_vm.candidate != null ? _vm.candidate.name : "")
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("email:")]),
              _vm._v(
                " " + _vm._s(_vm.candidate != null ? _vm.candidate.email : "")
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("nasc:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.candidate != null ? _vm.candidate.birthDate : "")
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("gênero:")]),
              _vm._v(
                " " + _vm._s(_vm.candidate != null ? _vm.candidate.sex : "")
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("tel:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null
                      ? _vm.candidate.primary_cell_phone
                      : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("cidade:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null ? _vm.candidate.primary_city : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("estado:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null ? _vm.candidate.primary_state : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("país:")]),
              _vm._v(
                " " + _vm._s(_vm.candidate != null ? _vm.candidate.country : "")
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ma-0 mt-6",
          attrs: { align: "start", justify: "start", dense: "" }
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", [_vm._v("Dados Acadêmicos")]),
            _c("hr")
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("formação:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null ? _vm.candidate.nivelFormacao : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "8" } }, [
            _c("span", [
              _c("strong", [_vm._v("Instituição:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null ? _vm.candidate.nomeDaInstituicao : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("curso:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null ? _vm.candidate.cursoSuperior : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "3" } }, [
            _c("span", [
              _c("strong", [_vm._v("conclusão:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null ? _vm.candidate.dataConclusao : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "3" } }, [
            _c("span", [
              _c("strong", [_vm._v("cidade:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null ? _vm.candidate.cidadeInstituicao : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "2" } }, [
            _c("span", [
              _c("strong", [_vm._v("país:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null ? _vm.candidate.paisInstituicao : ""
                  )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ma-0 mt-6",
          attrs: { align: "start", justify: "start", dense: "" }
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", [_vm._v("PosComp")]),
            _c("hr")
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("número:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.candidate != null ? _vm.candidate.poscompNumber : ""
                  )
              )
            ])
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("Instituição:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.candidate != null ? _vm.candidate.poscompYear : "")
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ma-0 mt-6",
          attrs: { align: "start", justify: "start", dense: "" }
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", [_vm._v("Dados Profissionais")]),
            _c("hr")
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
            _c("span", [
              _c("strong", [_vm._v("empresa:")]),
              _vm._v(
                " " + _vm._s(_vm.candidate != null ? _vm.candidate.company : "")
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }