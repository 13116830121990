<template>
  <div>
    <v-row align="start" justify="start" dense class="ma-0 mt-6">
      <v-col cols="12">
        <h3>Dados da Solicitação</h3>
        <hr />
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <span
          ><strong>disciplina:</strong>
          {{ request != null ? request.classTO.subject.code : "" }}</span
        >
      </v-col>
       <v-col cols="12" sm="12" md="2">
        <span
          ><strong>turma:</strong>
          {{ request != null ? request.classTO.code : "" }}</span
        >
      </v-col>
       <v-col cols="12" sm="12" md="2">
        <span
          ><strong>semestre:</strong>
          {{ request != null ? request.classTO.semester : "" }}</span
        >
      </v-col>
       <v-col cols="12" sm="12" md="2">
        <span
          ><strong>ano:</strong>
          {{ request != null ? request.classTO.year : "" }}</span
        >
      </v-col>
       <v-col cols="12" sm="12" md="2">
        <span
          ><strong>opção:</strong>
          {{ request != null ? request.priority : "" }}</span
        >
      </v-col>
       <v-col cols="12" sm="12" md="4">
        <span
          ><strong>situação:</strong>
          {{ request != null ? request.deferred : "" }}</span
        >
      </v-col>
        <v-col cols="12" sm="12" md="8">
        <span
          ><strong>objetivo:</strong>
          {{ request != null ? request.objective : "" }}</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ResquestInfo",
  props: ["id"],

  data() {
    return {
      disbaled: false,
      request: null,
    };
  },

  created() {
    if (this.id)
    this.getAsyncEnrollmentRequestById(this.id);
  },

  methods: {
    ...mapActions("candidateEnrollment", ["ActionGetEnrollmentRequestById"]),

    async getAsyncEnrollmentRequestById(id) {
      try {
        this.disabled = true;
        let res = await this.ActionGetEnrollmentRequestById(id);
        if (res.status == 200)
          this.request = res.body;
      } catch (e) {
        console.error(e);
      } finally {
        this.disabled = false;
      }
    },
  },

  watch: {
    id(newValue){
      this.getAsyncEnrollmentRequestById(newValue);
    }
  }
};
</script>

<style>
</style>