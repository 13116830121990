var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("Info", { attrs: { candidate: _vm.candidate } }),
      _c("RequestInfo", {
        staticClass: "mt-10",
        attrs: { id: _vm.request.id }
      }),
      _c("FilesTable", {
        staticClass: "mt-10",
        attrs: { username: _vm.candidate.email, readonly: true }
      }),
      _c(
        "v-row",
        {
          staticClass: "ma-0 mt-5 mb-10",
          attrs: { align: "start", justify: "start", dense: "" }
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", [_vm._v("Conceito")]),
            _c("hr")
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("OpinionEnrollmentRequest", {
                attrs: { requestId: _vm.request.id, op: _vm.opinion }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "2" } },
            [
              _c("v-select", {
                attrs: {
                  outlined: "",
                  disabled: _vm.conceitos.length == 0,
                  items: _vm.conceitos,
                  "item-text": "grade",
                  "return-object": "",
                  dense: "",
                  hint: "Escolha uma opção",
                  "persistent-hint": ""
                },
                model: {
                  value: _vm.conceito,
                  callback: function($$v) {
                    _vm.conceito = $$v
                  },
                  expression: "conceito"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "2" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    color: "green",
                    disabled: _vm.conceito == null
                  },
                  on: {
                    click: function($event) {
                      return _vm.submitOpinion()
                    }
                  }
                },
                [
                  _vm._v("Enviar"),
                  _c("v-icon", { staticClass: "ml-2" }, [_vm._v("send")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c("v-row", { attrs: { justify: "center", align: "center" } }, [
            _vm._v(_vm._s(_vm.snackBarMessage))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }