<template>
  <v-container>
    <Info :candidate="candidate"></Info>

    <RequestInfo :id="request.id" class="mt-10"></RequestInfo>

    <FilesTable
      :username="candidate.email"
      :readonly="true"
      class="mt-10"
    ></FilesTable>

    <v-row align="start" justify="start" dense class="ma-0 mt-5 mb-10">
      <v-col cols="12">
        <h3>Conceito</h3>
        <hr />
      </v-col>
      <v-col cols="12">
        <OpinionEnrollmentRequest :requestId="request.id" :op="opinion"></OpinionEnrollmentRequest>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-select
          outlined
          :disabled="conceitos.length == 0"
          :items="conceitos"
          :item-text="'grade'"
          return-object
          dense
          hint="Escolha uma opção"
          persistent-hint
          v-model="conceito"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn
          outlined
          color="green"
          :disabled="conceito == null"
          @click="submitOpinion()"
          >Enviar<v-icon class="ml-2">send</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      <v-row justify="center" align="center">{{ snackBarMessage }}</v-row>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Info from "./Info.vue";
import RequestInfo from "./RequestInfo";
import FilesTable from "@/components/common/file/FilesTable";
import OpinionEnrollmentRequest from "@/components/candidate/opinion_enrollment_request/OpinionEnrollmentRequest";

export default {
  name: "CandidateInfo",
  props: ["item"],
  components: { Info, RequestInfo, FilesTable, OpinionEnrollmentRequest },

  data() {
    return {
      conceito: null,
      conceitos: [],
      loading: false,
      request: null,
      candidate: {},
      snackbar: false,
      timeout: 3000,
      snackBarMessage: "",
      opinion: {},
    };
  },
  created() {
    this.request = this.$route.params.id;
    this.getAllOpinionGrades();
  },

  methods: {
    ...mapActions("opinionGrade", ["ActionGetAllOpinionGrade"]),
    ...mapActions("opinionEnrollmentRequest", ["ActionPostOpinion"]),

    async getAllOpinionGrades() {
      try {
        this.disable = true;
        await this.ActionGetAllOpinionGrade();
      } catch (e) {
        console.error(e);
        this.snackbar = true;
        this.snackBarMessage =
          "Erro ao buscar as notas para serem aplicadas à solicitação!";
      } finally {
        this.disable = false;
      }
    },

    async submitOpinion() {
      let payload = {
        requestId: this.request.id,
        gradeId: this.conceito.id,
      };

      try {
        let res = await this.ActionPostOpinion(payload);
        this.opinion = res.body;
        this.request.id = this.opinion.enrollmentRequest.id;
        this.snackbar = true;
        this.snackBarMessage = "Nota enviada com sucesso!";
      } catch (e) {
        console.error(e);
        this.snackbar = true;
        this.snackBarMessage = "Erro ao registrar a nota do aluno!";
      } finally {
        this.disable = false;
      }
    },
  },

  computed: {
    ...mapState("opinionGrade", ["grades"]),
  },

  watch: {
    grades(newValue) {
      this.conceitos = newValue;
    },

    request(newValue) {
      if (typeof newValue !== "string") {
        this.candidate = newValue.candidate.candidate;
      }
    },
  },
};
</script>